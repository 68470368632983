import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  forwardRef
} from '@angular/core';
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  ReactiveFormsModule
} from '@angular/forms';
import phoneInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

const langToUse = (lang: string) => {
  if (lang === 'en') {
    return 'us';
  }

  if (lang === 'spama') {
    return 'no';
  }

  return lang || 'en';
};

/*
This component is a custom phone input field with an integrated dropdown for selecting a country code.
It auto-detects if an incoming number already has a country code,
and outputs a trimmed string with the selected country code.

The component is compatible with ng-forms, but does also work standalone using its input and output.

The component is build around the npm package 'intl-tel-input' to provide the dropdown and extra functionality.
*/

@Component({
  selector: 'zlo-phone-input',
  template: `
    <input
      type="tel"
      id="phonefield"
      class="iti"
      [value]="value"
      (keypress)="numberOnly($event)"
      (input)="onInput()"
      (blur)="onBlur()"
    />
  `,
  styleUrls: ['./phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneInputComponent)
    }
  ]
})
export class PhoneInputComponent
  implements AfterViewInit, ControlValueAccessor, ReactiveFormsModule {
  public phoneInputComponent = new UntypedFormControl('');

  private onChange: (newNumber: string) => void;
  private onTouched: () => void;

  // Interface if the component is used standalone outside a form:
  @Input() value = '';
  @Input() initialCountry = 'en';
  @Output() updatedNumber = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>(true);

  phoneInputInstance: any;

  ngAfterViewInit() {
    const phone = document.querySelector('#phonefield');

    this.phoneInputInstance = new phoneInput(phone, {
      // any initialisation options go here
      autoPlaceholder: 'polite',
      initialCountry: langToUse(this.initialCountry),
      preferredCountries: ['us', 'gb', 'no', 'dk', 'se', 'fi'],
      separateDialCode: true,
      formatOnDisplay: true
    });

    this.updateValidity();

    phone.addEventListener('countrychange', () => {
      this.onInput();
    });
  }

  // When using the component outside a form, this function needs to be called in the parrent to emit the field's current value to the output.
  outputNumber() {
    this.updatedNumber.emit(this.phoneInputInstance.getNumber());
  }

  // The following four functions belong to the extented ControlValueAccessor interface.
  writeValue(obj: string) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.phoneInputComponent.disable();
    } else {
      this.phoneInputComponent.enable();
    }
  }

  numberOnly(e: any) {
    const regex = new RegExp('^[(+)0-9]$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  //  Updates the form control's values when a change is made to the input.
  onInput() {
    this.updateValidity();
    this.onChange(this.phoneInputInstance.getNumber());
  }

  onBlur() {
    this.onTouched();
  }

  updateValidity() {
    this.isValid.emit(
      this.phoneInputInstance.getNumber()
        ? (window as any).intlTelInputUtils.isValidNumber(
            this.phoneInputInstance.getNumber()
          )
        : true
    );
  }
}
