import { SignupComponent } from './auth/containers/signup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { RegistrationPageComponent } from './auth/containers/registration-page.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'register',
    component: AuthComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
      { path: 'free-trial', component: RegistrationPageComponent }
    ]
  },
  {
    path: 'signup',
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
      { path: ':zeloId', component: SignupComponent }
    ]
  },
  {
    path: 'response',
    loadChildren: () =>
      import('./enduserPortal/end-user.module').then((m) => m.EndUserModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./adminPortal/admin.module').then((m) => m.AdminModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      // preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
