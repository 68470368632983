import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';

import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EMAIL_PATTERN } from 'src/app/shared/util';
import { UserValidators } from '../user.util';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Subscription, catchError, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

enum Modes {
  Initializing = 'Initializing',
  PresentForm = 'PresentForm',
  Anonymous = 'Anonymous',
  IsSigningUp = 'IsSigningUp',
  SignupSuccess = 'SignupSuccess',
  TryAgain = 'TryAgain'
}

@Component({
  selector: 'zlo-signup',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions = new Subscription();
  userForm: FormGroup;
  zeloId = '';
  zeloSignup: any = null;
  mode = Modes.Initializing;
  formSubmitted = false;
  isPhoneNumberValid = true;
  isAnonymous = false;
  isSigningUpAnonymous = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    public toastr: ToastrService,
    private router: Router
  ) {}

  get attributes(): FormArray {
    return this.userForm.get('attributes') as FormArray;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.zeloId = this.zeloId || params?.zeloId || null;
      })
    );

    this.userForm = this.fb.group(
      {
        firstname: ['', Validators.compose([Validators.required])],
        lastname: ['', Validators.compose([Validators.required])],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(EMAIL_PATTERN)
          ])
        ],
        password: ['', UserValidators.requiredPasswordExtended]
      },
      { updateOn: 'blur' }
    );
  }

  ngAfterViewInit() {
    if (!this.zeloId) return;

    this.subscriptions.add(
      this.authService
        .getMinimalZeloInfo(this.zeloId)
        .subscribe((zelo: any) => {
          this.zeloSignup = this.zeloSignup || zelo;
          this.translate.use(
            zelo?.signupLanguage ?? zelo?.organization?.language ?? 'en'
          );

          this.mode = this.zeloSignup.anonymous
            ? Modes.Anonymous
            : this.zeloSignup
            ? Modes.PresentForm
            : Modes.TryAgain;
          this.isAnonymous = this.zeloSignup?.anonymous ?? false;

          if (this.zeloSignup && !this.isAnonymous) {
            this.userForm = this.fb.group(
              {
                firstname: ['', Validators.compose([Validators.required])],
                lastname: ['', Validators.compose([Validators.required])],
                email: [
                  '',
                  Validators.compose([
                    Validators.required,
                    Validators.pattern(EMAIL_PATTERN)
                  ])
                ],
                phone: ['', Validators.compose([Validators.required])],
                attributes: this.fb.array([])
              },
              { updateOn: 'change' }
            );

            this.zeloSignup?.attributes?.forEach((attribute: string) => {
              let attribuesArray = this.userForm.get('attributes') as FormArray;
              const attributeControl = this.fb.group({
                name: [attribute.toLowerCase()],
                value: [
                  '',
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(48)
                  ])
                ]
              });

              attribuesArray.push(attributeControl);
            });
          }

          this.cdr.detectChanges();
        })
    );
  }

  shouldShowGrass() {
    return this.mode === Modes.Initializing;
  }

  shouldShowForm() {
    return this.mode === Modes.PresentForm;
  }

  shouldShowAnonymous() {
    return this.mode === Modes.Anonymous;
  }

  shouldShowLoader() {
    return this.mode === Modes.IsSigningUp;
  }

  shouldShowSuccess() {
    return this.mode === Modes.SignupSuccess;
  }

  shouldShowTryAgain() {
    return this.mode === Modes.TryAgain;
  }

  addUser() {
    this.formSubmitted = true;
    if (this.userForm.valid) {
      const user = this.userForm.value;
      this.mode = Modes.IsSigningUp;

      this.authService
        .signupForZelo(this.zeloId, user)
        .pipe(
          take(1),
          catchError(() => (this.mode = Modes.TryAgain))
        )
        .subscribe((data: any) => {
          if (data?.user_exists) {
            this.mode = Modes.PresentForm;
            const i18n = 'signup.messageSignup.alreadySignedUp';
            return this.toastr.show('', this.translate.instant(i18n), {
              toastClass: 'zelo-toast zelo-toast-success'
            });
          }

          this.mode = Modes.SignupSuccess;
        });
    }
  }

  addAnonymousUser() {
    this.progressMessage();
    this.authService
      .signupForZelo(this.zeloId, { anonymous: true })
      .pipe(take(1))
      .subscribe((data: any) => {
        this.router.navigate(['response', data.responseId], {
          queryParams: { useEmail: 'false' }
        });
      }),
      catchError((error) => {
        console.log(error);
        this.isSigningUpAnonymous = '';
        return (this.mode = Modes.TryAgain);
      });
  }

  isValidFn(event: boolean) {
    this.isPhoneNumberValid = event;
    if (this.zeloSignup) {
      if (!this.isPhoneNumberValid) {
        this.userForm.controls['phone'].setErrors({
          invalid: this.isPhoneNumberValid
        });
      } else {
        this.userForm.controls['phone'].setErrors(null);
      }
    }
  }

  formatEmail() {
    const emailControl = this.userForm.controls['email'];
    return emailControl.setValue(emailControl.value.toLowerCase().trim());
  }

  progressMessage() {
    this.isSigningUpAnonymous = 'signup.messageSignup.creatingUser';

    setTimeout(() => {
      if (this.isSigningUpAnonymous === '') return;
      this.isSigningUpAnonymous = 'signup.messageSignup.preparingLandingPage';
    }, 2500);
    setTimeout(() => {
      if (this.isSigningUpAnonymous === '') return;
      this.isSigningUpAnonymous = 'signup.messageSignup.finalizing';
    }, 5000);
  }

  attributeName(name: string) {
    const lang = this.zeloSignup.signupLanguage;
    const langToUse = (lang === 'spama' ? 'no' : lang) || 'en';
    const rawAttribute = this.zeloSignup?.rawAttributes?.find(
      (a: any) => a.attr.toLowerCase() === name.toLowerCase()
    );

    return rawAttribute?.i18n[langToUse] || rawAttribute?.i18n['en'] || name;
  }
}
